/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { getRamenServicesUrl } from 'lib/getRamenServicesUrl';
import { isBrowser } from 'lib/BrowserDetection';
import './styles.themed.scss';
import { GridStack } from 'components/GridStack';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { logError } from 'lib/datadog';

import { v4 as uuidv4 } from 'uuid';
import { useConsentStatus } from '@nbcnews/gpp-utilities';
import {
  VideoContextProvider,
  Subtitles,
  TapToUnmuteButton,
  CoreVideoPlayer,
  VideoControlRack,
} from './dynamicComponents';
import { StickyPipControl } from './StickyPipControl';
import { getUsPrivacy } from './detectBrowserPrivacy';
import { VideoLoadingAnimation } from './LoadingAnimation/Loader';
import { OmegaSlate } from './OmegaSlate';
import { InactivityMonitorUpdate } from './InactivityMonitorUpdate';
import { useMonkeyPatchFetch } from './useMonkeyPatchFetch';
import { useIsGeoRestricted } from './useIsGeoRestricted';
import { ErrorSlate } from './ErrorSlate';

/**
 * @param {number} timestamp
 * @param {string} drmType
 * @returns {Promise<string>}
 */
export async function signRequest(timestamp, drmType) {
  const url = `${getRamenServicesUrl()}/signLicenseRequest?ts=${timestamp}&drmType=${drmType}`;
  try {
    const response = await fetch(url, { cache: 'no-store' });
    return response.json();
  } catch (error) {
    const errorMessage = 'Error in OmegaVideoPlayer signRequest';
    logError(`[Default Error Message] ${error}, ${errorMessage}`, '500');
    throw error;
  }
}

/**
 * @param {object} props
 * @param {boolean} [props.autoplay] Automatically start the video
 * @param {boolean} [props.mutedAutoplay] If autoplay is enabled, the video will automatically start with audio muted
 * @param {boolean} [props.stickyEnabled] Allow picture-in-picture mode when scrolling
 * @param {boolean} [props.hasClickedToPlay] Tracks if the user has clicked to play on the parent component
 * @param {string} [props.mvpdHash]
 * @param {import('lib/liveVideo').STREAM_KEY} props.stream
 * @param {Function} [props.updateIsVideoPlaying] Callback to update the parent component when the video is playing in TVE class component
 * @param {Function} props.onStuckChange
 */
export function OmegaVideoPlayer({
  stream,
  autoplay,
  mutedAutoplay,
  mvpdHash,
  stickyEnabled,
  hasClickedToPlay,
  onStuckChange = () => {},
  updateIsVideoPlaying = () => {},
}) {
  const { data: gppData } = useConsentStatus();

  useMonkeyPatchFetch();
  const isGeoRestricted = useIsGeoRestricted();

  const [coreVideoReady, setCoreVideoReady] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorCause, setErrorCause] = useState({});
  const videoContainerRef = useRef(null);
  let mparticleId = null;

  if (isBrowser()) {
    try {
      //* Grabs the BI_UI_mpid cookie, which is the mparticle ID
      //* Falls back to a random UUID, per https://nbcnewsdigital.atlassian.net/browse/NGSP-277
      //* BI gets loaded via Adobe Launch, so eslint marks it as undefined
      // eslint-disable-next-line no-undef
      mparticleId = BI?.userInfo?.get('mpid') || uuidv4();
    } catch (error) {
      mparticleId = uuidv4();
    }
  } else {
    return null;
  }

  const geoRestrictedHeading = 'We’re sorry.';
  const geoRestrictedDetails = 'You are not authorized to access this content from outside of the US and its territories.';

  if (isGeoRestricted) {
    return <ErrorSlate slateType="geo-restricted" heading={geoRestrictedHeading} details={geoRestrictedDetails} />;
  }

  const content = (
    <GridStack className="omega_videoContainer" ref={videoContainerRef} stickyEnabled>
      <TapToUnmuteButton />
      <CoreVideoPlayer
        signRequest={signRequest}
        usPrivacy={getUsPrivacy()}
        mutedAutoplay={mutedAutoplay}
        streamId={stream}
        mvpdHash={mvpdHash}
        onVideoControllerReady={() => {
          setCoreVideoReady(true);
          updateIsVideoPlaying(true);
        }}
        gpp={gppData}
        autoplay={autoplay || hasClickedToPlay}
        mParticleId={mparticleId}
      />
      {!hasError && coreVideoReady && <VideoLoadingAnimation isReady={coreVideoReady} />}
      <VideoControlRack showPipButton />
      <Subtitles />
    </GridStack>
  );

  return (
    <GridStack data-testid="omega-video-player" className="omega-container">
      <ErrorBoundary
        errorHandler={() => <OmegaSlate coreVideoReady={coreVideoReady} hasError errorCause={errorCause} stream={stream} />}
        errorLogger={logError}
      >
        <OmegaSlate
          coreVideoReady={coreVideoReady}
          hasError={hasError}
          stream={stream}
          errorCause={errorCause}
        />
        <VideoContextProvider
          onError={({ error, context }) => {
            logError(error, { ...context, stream, cause: error.cause });
            setHasError(true);
            setErrorCause(error.cause);
          }}
          videoContainerRef={videoContainerRef}
          onNonFatalError={({ error, context }) => {
            logError(error, { ...context, stream, cause: error.cause });
          }}
        >
          {!hasError && !coreVideoReady && <VideoLoadingAnimation isReady={coreVideoReady} />}
          <InactivityMonitorUpdate isStickyEnabled={stickyEnabled || false} />
          <div className="omega_aspectRatioContainer">
            {stickyEnabled ? <StickyPipControl onStuckChange={onStuckChange}>{content}</StickyPipControl> : content}
          </div>
        </VideoContextProvider>
      </ErrorBoundary>
    </GridStack>
  );
}

OmegaVideoPlayer.defaultProps = {
  mvpdHash: '',
  autoplay: false,
  mutedAutoplay: false,
  stickyEnabled: false,
  hasClickedToPlay: false,
  /**
   *
   */
  updateIsVideoPlaying: () => {},
};

OmegaVideoPlayer.propTypes = {
  mvpdHash: PropTypes.string,
  autoplay: PropTypes.bool,
  mutedAutoplay: PropTypes.bool,
  stickyEnabled: PropTypes.bool,
  hasClickedToPlay: PropTypes.bool,
  stream: PropTypes.string.isRequired,
  updateIsVideoPlaying: PropTypes.func,
};
